import { Button, SettingsIcon, X } from '@vereign/ui';
import React, { Suspense } from 'react';
import LanguageSelect from '../LanguageSelect/LanguageSelect';
import { usePathname, useSearchParams } from 'next/navigation';
import appRoutes from '@/app/appRoutes';
import Spinner from '../Spinner/Spinner';
import { SEALAudience } from '@/app/_types/DataTypes';
import { LinkWithLocale } from 'next-export-i18n';
import useUserStore from '@/app/_stores/UserStore';
import useQRStore from '@/app/_stores/QRStore';

function Header() {
  const isAuthenticated = useUserStore((state) => state.isAuthenticated);
  const audience = useQRStore((state) => state.audience);

  const searchParams = useSearchParams();

  const pathname = usePathname();
  const q = searchParams.get('q');

  return (
    <div
      data-testid="header"
      className="mb-4 flex w-full max-w-screen-2xl flex-row items-center justify-between gap-4"
    >
      <div className="flex flex-1 lg:flex-[2.5]">
        <img
          data-testid="hin-logo-img"
          src="/graphics/hin-logo.png"
          alt="HIN Logo"
          className="w-28"
        />
      </div>
      <div className="flex min-w-48 flex-1 animate-flip-in-from-right justify-end gap-4 lg:min-w-80">
        {isAuthenticated && audience !== SEALAudience.PUBLIC ? (
          appRoutes.seal === pathname ? (
            <Button
              data-testid="settings-button"
              variant="round"
              size="icon"
              asChild
              className="min-h-12 min-w-12 bg-primary"
            >
              <LinkWithLocale
                title="open settings"
                href={`${appRoutes.settings}?q=${q}`}
              >
                <SettingsIcon className="h-7 w-7" />
              </LinkWithLocale>
            </Button>
          ) : (
            <Button
              data-testid="close-settings-button"
              variant="round"
              size="icon"
              className="min-h-12 min-w-12 bg-primary"
              asChild
            >
              <LinkWithLocale
                title="close settings"
                href={`${appRoutes.seal}?q=${q}`}
              >
                <X className="h-4 w-4" />
              </LinkWithLocale>
            </Button>
          )
        ) : isAuthenticated === false || audience === SEALAudience.PUBLIC ? (
          <>
            <div className="w-10"></div>
            <LanguageSelect hideIconOnMobile />
          </>
        ) : null}
      </div>
    </div>
  );
}

export default function HeaderWrapper() {
  return (
    <Suspense fallback={<Spinner />}>
      <Header />
    </Suspense>
  );
}
