import React from 'react';
import { twMerge } from 'tailwind-merge';

export interface SkipLinkProps {
  href: string;
  children: React.ReactNode;
}

function SkipLink({ href, children }: SkipLinkProps) {
  return (
    <a
      className={twMerge(
        'absolute left-1/2 m-3 -translate-x-1/2 -translate-y-16 rounded-lg bg-primary p-3',
        'text-white transition focus:translate-y-0 lg:left-0 lg:translate-x-0',
        'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring',
        'ring-offset-background focus-visible:ring-offset-2',
      )}
      href={href}
    >
      {children}
    </a>
  );
}

export default SkipLink;
