import { create } from 'zustand';

export interface UserObject {
  messageHash: string;
}

interface UserState {
  user: UserObject | undefined;
  setUser: (_qr: UserObject) => void;
  userEmail: string | undefined;
  setUserEmail: (_a: string) => void;
  isAuthenticated: boolean | undefined;
  setIsAuthenticated: (_a: boolean) => void;
  replyOpen: boolean;
  setReplyOpen: (_a: boolean) => void;
}

const useUserStore = create<UserState>((set) => ({
  user: undefined,
  setUser: (_u) => set((state) => ({ user: _u })),

  userEmail: undefined,
  setUserEmail: (_a) => set((state) => ({ userEmail: _a })),

  isAuthenticated: undefined,
  setIsAuthenticated: (_a) => set((state) => ({ isAuthenticated: _a })),

  replyOpen: false,
  setReplyOpen: (_a) => set((state) => ({ replyOpen: _a })),
}));

export default useUserStore;
