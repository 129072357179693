import { useEffect } from 'react';
import { Config } from '../_config/schema';
import { useConfigStore } from '../_stores/AppConfigStore';

export function useConfig(): {
  config: Config | null;
  fetchConfig: () => Promise<Config>;
} {
  const { config, fetchConfig } = useConfigStore();

  useEffect(() => {
    if (!config) {
      fetchConfig();
    }
  }, [config, fetchConfig]);

  return { config, fetchConfig };
}
