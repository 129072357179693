import React from 'react';
import styles from './Spinner.module.css';

type ColorsType = 'white' | 'black' | 'turq';

interface Props {
  width?: number | string;
  height?: number | string;
  className?: string;
  color?: ColorsType;
}

const Spinner = ({ className, width = 32, height = 32, color }: Props) => {
  const spinnerWhite = '/graphics/spinner/spinner-white.svg';
  const spinnerBlack = '/graphics/spinner/spinner-black.svg';
  const spinnerTurq = '/graphics/spinner/spinner-turq.svg';

  let icon = (() => {
    switch (true) {
      case color === 'white':
        return spinnerWhite;
      case color === 'black':
        return spinnerBlack;
      case color === 'turq':
        return spinnerTurq;
      default:
        return spinnerBlack;
    }
  })();

  return (
    <img
      alt="spinner"
      width={width}
      height={height}
      className={styles.spinner + ' ' + className}
      src={icon}
    />
  );
};

export default Spinner;
