import React, { Suspense } from 'react';
import Spinner from '../Spinner/Spinner';
import SkipLink from './SkipLink/SkipLink';
import { usePathname } from 'next/navigation';
import appRoutes from '@/app/appRoutes';
import { useTranslation } from 'next-export-i18n';
import useUserStore from '@/app/_stores/UserStore';

export interface SkipLinkData {
  href: string;
  textKey: string;
}

const authPageSkipLinks = [
  {
    href: '#login-form',
    textKey: 'common.skipLinks.loginForm',
  },
];

const emailPageSkipLinks = [
  {
    href: '#email-meta',
    textKey: 'common.skipLinks.emailMeta',
  },
  {
    href: '#skip-to-email-content',
    textKey: 'common.skipLinks.emailBody',
  },
];

const settingsPageSkipLinks = [
  {
    href: '#phone-input',
    textKey: 'common.skipLinks.phoneChange',
  },
];

function SkipLinks() {
  const { t } = useTranslation();
  let skipLinks: SkipLinkData[] = [];

  const isAuthenticated = useUserStore((state) => state.isAuthenticated);

  const pathname = usePathname();

  if (appRoutes.seal === pathname) {
    if (!isAuthenticated) {
      skipLinks = authPageSkipLinks;
    } else {
      skipLinks = emailPageSkipLinks;
    }
  } else {
    skipLinks = settingsPageSkipLinks;
  }

  return skipLinks.length > 0 ? (
    <ul>
      {skipLinks.map(({ href, textKey }) => (
        <li key={href}>
          <SkipLink href={href}>{t(textKey)}</SkipLink>
        </li>
      ))}
    </ul>
  ) : null;
}

export default function SkipLinksWrapper() {
  return (
    <Suspense fallback={<Spinner />}>
      <SkipLinks />
    </Suspense>
  );
}
